<template>
  <b-overlay
    v-if="card.state == 'WAITING'"
    :show="true"
    rounded="sm"
    class="position-absolute w-full h-full top-0 left-0 busy-modal-center-vh"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    bg-color="#ffffff"
  >
    <template #overlay>
      <div class="text-center">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
        <p>Please wait...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BIcon, BButton, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BIcon,
    BButton,
    BSpinner,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>

<style>
.busy-modal-center-vh div > div:last-child {
  top: 50vh !important;
}
</style>
