import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import _ from 'lodash'
import moment from "moment-timezone";

moment.locale("en");

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { showErrorToast } from '@leecom/utils/support'

export default function useFetchData() {
    const STORE_MODULE_NAME = 'app-reports-consumption-area-wise'

    const busyCard = ref({
        state: null,
    })

    const toast = useToast();

    const titleCard = computed(() => {
        return store.state[STORE_MODULE_NAME].titleCard
    })

    const refTableCard = ref(null)

    const mode = computed(() => {
        return store.state[STORE_MODULE_NAME].mode
    })

    const actionData = computed(() => {
        return store.state[STORE_MODULE_NAME].actionData
    })

    const getPayload = () => {
        const filters = actionData.value.filters
        const { startDate, endDate } = actionData.value.rangeDate

        const { perPage, currentPage, sortBy, isSortDirDesc } = refTableCard.value

        const payload = {
            areaType: filters.areaType,
            startTimestamp: moment
                .tz(startDate.format('YYYY-MM-DD 00:00:00'), 'UTC')
                .valueOf(),
            endTimestamp: moment
                .tz(endDate.format('YYYY-MM-DD 23:59:59'), 'UTC')
                .valueOf(),
            queryParams: {
                perPage: perPage,
                page: currentPage,
                sortBy: sortBy,
                sortDesc: isSortDirDesc,
            },
        }

        return payload;
    }

    const openPDF = () => {
        const payload = getPayload();

        busyCard.value.state = 'WAITING';

        store.dispatch(`${STORE_MODULE_NAME}/openPDF`, payload)
            .then((response) => {
                const fileName = response.headers['file-name'];
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();

                busyCard.value.state = null;
            })
            .catch((error) => {
                console.log(error);
                const { response } = error;
                busyCard.value.state = null;
                showErrorToast(toast, response)
            })
    }

    const downloadExcel = () => {
        const payload = getPayload();

        busyCard.value.state = 'WAITING';

        store.dispatch(`${STORE_MODULE_NAME}/downloadExcel`, payload)
            .then((response) => {
                const fileName = response.headers['file-name'];
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();

                busyCard.value.state = null;
            })
            .catch((error) => {
                console.log(error);
                const { response } = error;
                busyCard.value.state = null;
                showErrorToast(toast, response)
            })
    }


    const fetchReportTitle = () => {

        store
            .dispatch(`${STORE_MODULE_NAME}/setTitle`, {
                data: null,
                state: 'WAITING',
            })

        const payload = getPayload();

        return new Promise((resolve, reject) => {
            store
                .dispatch(`${STORE_MODULE_NAME}/fetchTitle`, payload)
                .then((response) => {
                    store
                        .dispatch(`${STORE_MODULE_NAME}/setTitle`, {
                            data: response,
                            state: 'OK',
                        })
                    resolve(response);
                })
                .catch((error) => {
                    store
                        .dispatch(`${STORE_MODULE_NAME}/setTitle`, {
                            data: null,
                            state: 'ERROR',
                        })
                    reject(error);
                })
        });
    }

    const fetchReportChart = () => {

        store
            .dispatch(`${STORE_MODULE_NAME}/setChart`, {
                data: null,
                state: 'WAITING',
            })

        const payload = getPayload();

        if (payload.areaType == 'all') {
            store
                .dispatch(`${STORE_MODULE_NAME}/setChart`, {
                    data: null,
                    state: 'HIDDEN',
                })
            return;
        }

        return new Promise((resolve, reject) => {
            store
                .dispatch(`${STORE_MODULE_NAME}/fetchChart`, payload)
                .then((response) => {
                    store
                        .dispatch(`${STORE_MODULE_NAME}/setChart`, {
                            data: response,
                            state: 'OK',
                        })
                    resolve(response);
                })
                .catch((error) => {
                    store
                        .dispatch(`${STORE_MODULE_NAME}/setChart`, {
                            data: null,
                            state: 'ERROR',
                        })
                    reject(error);
                })
        });
    }

    const fetchReportTable = () => {
        store
            .dispatch(`${STORE_MODULE_NAME}/setTable`, {
                data: null,
                state: 'WAITING',
            })

        const payload = getPayload();

        return new Promise((resolve, reject) => {
            store
                .dispatch(`${STORE_MODULE_NAME}/fetchTable`, payload)
                .then((response) => {
                    store
                        .dispatch(`${STORE_MODULE_NAME}/setTable`, {
                            data: response,
                            state: 'OK',
                        })
                    resolve(response);
                })
                .catch((error) => {
                    store
                        .dispatch(`${STORE_MODULE_NAME}/setTable`, {
                            data: null,
                            state: 'ERROR',
                        })
                    reject(error);
                })
        });
    }

    const generateReport = () => {
        fetchReportTitle()

        fetchReportChart()

        fetchReportTable()
    }

    return {
        generateReport,
        openPDF,
        downloadExcel,

        titleCard,
        busyCard,

        refTableCard,
    }
}