<template>
  <highcharts
    ref="chart"
    :constructor-type="'chart'"
    :options="options"
    class="h-100"
  />
</template>

<script>
import { Chart } from 'highcharts-vue'
import More from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsNoData from 'highcharts-no-data-to-display';
HighchartsNoData(Highcharts);
More(Highcharts)

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  labels: {
    style: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  xAxis: {
    labels: {
      style: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'bold',
      },
    },
    title: {
      style: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'bold',
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'bold',
      },
    },
    title: {
      style: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'bold',
      },
    },
  },
  tooltip: {
    style: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  lang: {
    thousandsSep: ',',
  },
})

export default {
  components: {
    highcharts: Chart,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.chart = this.$refs.chart.chart
    this.$emit('mounted', this.chart)
  },
  methods: {
    afterSetExtremes(event) {
      this.$emit('afterSetExtremes', event)
    },
  },
}
</script>
