<template>
  <b-card no-body>
    <b-card-body>
      <div class="text-center my-2">
        <slot name="description">
          {{ state ? descriptions[state] : descriptions['NO_INPUT'] }}
        </slot>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
  },
  props: {
    title: {
      type: Object,
    },
    state: {
      type: String,
      default: 'NO_DATA',
    },
  },
  data() {
    return {
      descriptions: {
        ERROR: 'There was an error. Please try again.',
        WAITING: 'Loading...',
        NO_INPUT: '',
        NO_DATA: 'No data found.',
        OK: '',
      },
    }
  },
}
</script>

<style></style>
