<template>
  <div>
    <b-card
      no-body
      :class="{ 'area-busy': card.state == 'WAITING' }"
    >
      <b-card-body v-if="card.data">
        <h5 class="text-center">
          {{ card.data.title }}
        </h5>
        <h5
          v-for="(item, index) in card.data.subTitle"
          :key="index"
          class="text-center"
        >
          {{ item }}
        </h5>
      </b-card-body>
      <card-placeholder
        v-else
        :state="card.state"
      />
    </b-card>
  </div>
</template>

<script>
import CardPlaceholder from '@leecom/card-placeholder/CardPlaceholder.vue'
import {
  BTabs, BTab, BCard, BCardBody, BCardHeader,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    CardPlaceholder,
  },
  props: {
    card: {
      type: Object,
    },
  },
}
</script>

<style></style>
