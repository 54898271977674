<template>
  <b-card
    v-if="chartCard.state != 'HIDDEN'"
    no-body
    class="w-full h-[calc(100vh-360px)] min-h-[400px] max-h-[400px]"
    :class="{ 'area-busy': chartCard.state == 'WAITING' }"
  >
    <b-card-body>
      <h-basic-chart
        v-if="chartCard.data"
        :options="chartOptions"
        :series="series"
        @mounted="chartMounted"
      />

      <card-placeholder
        v-else
        :state="chartCard.state"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCardHeader,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import HBasicChart from '@leecom/hc-charts/HBasicChart.vue'
import CardPlaceholder from '@leecom/card-placeholder/CardPlaceholder.vue'
import useChart from './useChart'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BDropdown,
    BDropdownItem,

    HBasicChart,
    CardPlaceholder,
  },
  setup() {
    const {
      chartCard,

      chartOptions,
      series,

      chartMounted,

      waiting,

      hasChart,
    } = useChart()

    return {
      chartCard,

      chartOptions,
      series,

      chartMounted,

      waiting,

      hasChart,
    }
  },
}
</script>
