<template>
  <b-card
    v-if="chartCard.state != 'HIDDEN'"
    no-body
    class="w-full min-h-[400px]"
    :class="{ 'area-busy': chartCard.state == 'WAITING' }"
  >
    <b-card-body>
      <div v-if="chartCard.state == 'OK'">
        <pie-chart v-if="chartCard.data.pieChart" />
        <column-chart v-if="chartCard.data.columnChart" />
      </div>
      <card-placeholder
        v-else
        :state="chartCard.state"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCardHeader,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import HBasicChart from '@leecom/hc-charts/HBasicChart.vue'
import CardPlaceholder from '@leecom/card-placeholder/CardPlaceholder.vue'
import useChart from './pie-chart/useChart'
import PieChart from './pie-chart/PieChart.vue'
import ColumnChart from './column-chart/ColumnChart.vue'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BDropdown,
    BDropdownItem,

    HBasicChart,
    CardPlaceholder,
    PieChart,
    ColumnChart,
  },
  setup() {
    const {
      chartCard,

      chartOptions,
      series,

      chartMounted,

      waiting,

      hasChart,
    } = useChart()

    return {
      chartCard,

      chartOptions,
      series,

      chartMounted,

      waiting,

      hasChart,
    }
  },
}
</script>
