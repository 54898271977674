import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import _ from 'lodash'
import moment from "moment-timezone";

moment.locale("en");

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useChart() {

    const toast = useToast()
    const STORE_MODULE_NAME = 'app-reports-consumption-area-wise'

    const chart = ref(null);

    const chartKey = 'columnChart';

    const chartCard = computed(() => {
        return store.state[STORE_MODULE_NAME].chartCard
    })

    const chartOptions = computed(() => {
        if (chartCard.value.data && chartCard.value.data[chartKey]) {
            const options = _.cloneDeep(
                chartCard.value.data[chartKey].chartOptions,
            )
            return options
        }
        return {}
    })

    const series = computed(() => {
        if (chartCard.value.data && chartCard.value.data[chartKey]) {
            return chartCard.value.data[chartKey].series
        }
        return null;
    })


    watch([series], () => {
        if (!series.value) {
            return;
        }
        updateChartSeries();
    })

    const updateChartSeries = () => {
        if (!chart || !chart.value || !chart.value.series) {
            return
        }

        while (chart.value.series.length) {
            chart.value.series[0].remove()
        }

        setTimeout(() => {
            series.value.forEach((data, index) => {
                chart.value.addSeries(data)
            })
        }, 100);
    }

    const chartMounted = (event) => {
        chart.value = event;
        if (chart.value) {
            updateChartSeries()
        }
    }


    return {
        chartCard,

        chartOptions,
        series,

        chartMounted,
    }
}